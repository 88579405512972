import { getUnixTimestamp } from 'common/date.helper';
import {
  StructureLevel,
  Tournoi,
  TournoiPlayer,
  AjouterKillValues,
} from '../tournoi.types';
import { getPreviousAndNextLevelsAtIndex } from '../structure.helper';
import { upsertTournoi, upsertTournoiStatus } from '../tournoi.api';
import { TournoiLive, TournoiLiveStructureState } from './tournoi.live.types';
import { useCallback, useEffect, useState } from 'react';
import { useParle } from 'common/textToSpeech.helper';
import { getRandomSon, useSons } from 'common/sons.helper';
import { getAudioPlayerName } from 'features/players/players.helpers';
import { formatMoney } from 'common/string.helper';
import { Player } from 'features/players/players.types';
import { useToast } from '@chakra-ui/react';
import TournoiLiveToast from './components/TournoiLiveToast';

const LIVE_TOAST_ID = 'liveToastId';

export function getStructureDurationInSeconds(structure: StructureLevel[]) {
  return structure.reduce<number>(
    (totalDuration, { duree }) => totalDuration + duree * 60,
    0,
  );
}

export function getTournoiElapsedTimeInSeconds(
  {
    lastRestartDate,
    elapsedSecInTournament,
    status,
  }: Pick<Tournoi, 'lastRestartDate' | 'elapsedSecInTournament' | 'status'>,
  currentTimestamp: number,
): number {
  const lastRestartTimestamp = getUnixTimestamp(lastRestartDate);
  switch (status) {
    case 'running': {
      const diff = Math.max(currentTimestamp - lastRestartTimestamp, 0);
      return elapsedSecInTournament + diff;
    }
    case 'notStarted':
    case 'paused':
    case 'finished': {
      return elapsedSecInTournament;
    }
    default: {
      const switchCheck: never = status;
      return switchCheck;
    }
  }
}

export function getTournoiElapsedSecSinceBeginningOfLevel(
  { structure }: Tournoi,
  levelIndex: number,
) {
  return structure.reduce((acc, level, i) => {
    return i < levelIndex ? acc + level.duree * 60 : acc;
  }, 0);
}

export function getCurrentStateOfTournamentStructure(
  tournoi: Tournoi,
  currentTimestamp: number,
): TournoiLiveStructureState {
  const { structure } = tournoi;
  if (!structure.length) {
    throw new Error('Un tournoi doit comporter au moins un niveau');
  }

  const structureLength = structure.length;
  let elapsedTime = getTournoiElapsedTimeInSeconds(tournoi, currentTimestamp);

  //Le temps passé sur le tournoi dépasse le temps prévu par la structure
  if (elapsedTime > getStructureDurationInSeconds(structure)) {
    return {
      currentLevel: {
        index: structureLength - 1,
        level: structure[structureLength - 1],
      },
      secondsLeftInLevel: 0,
      secondsLeftToNextPause: 0,
    };
  }

  const currentLevelIndex = structure.findIndex(({ duree, isPause }, i) => {
    const dureeInSec = duree * 60;

    // Si on arrive sur une pause qui n'a pas de durée c'est que c'est le niveau en cours
    if (elapsedTime === 0 && duree === 0 && isPause) {
      return true;
    }

    if (elapsedTime < dureeInSec) {
      return true;
    }
    elapsedTime = elapsedTime - dureeInSec;
    return false;
  });

  const {
    currentLevel,
    previousLevelIndex,
    previousLevel,
    previousLevelNotPauseIndex,
    previousLevelNotPause,
    nextLevelIndex,
    nextLevel,
    nextLevelNotPauseIndex,
    nextLevelNotPause,
    nextPauseIndex,
    nextPause,
  } = getPreviousAndNextLevelsAtIndex(structure, currentLevelIndex);

  // On fait une pause auto quand on arrive sur un niveau de pause sans durée
  if (
    currentLevel.isPause &&
    currentLevel.duree === 0 &&
    tournoi.status === 'running'
  ) {
    pauseOrResumeTournoi({ ...tournoi, currentTimestamp });
  }

  const secondsLeftInLevel = currentLevel.duree * 60 - elapsedTime;

  const secondsLeftToNextPause =
    secondsLeftInLevel +
    (nextPauseIndex
      ? [
          ...structure.slice(currentLevelIndex + 1, nextPauseIndex),
        ].reduce<number>((sec, { duree }) => sec + duree * 60, 0)
      : 0);

  return {
    currentLevel: {
      index: currentLevelIndex,
      level: currentLevel,
    },
    previousLevel: previousLevel
      ? {
          index: previousLevelIndex,
          level: previousLevel,
        }
      : undefined,
    previousLevelNotPause: previousLevelNotPause
      ? {
          index: previousLevelNotPauseIndex,
          level: previousLevelNotPause,
        }
      : undefined,
    nextLevel: nextLevel
      ? { index: nextLevelIndex, level: nextLevel }
      : undefined,
    nextLevelNotPause: nextLevelNotPause
      ? { index: nextLevelNotPauseIndex, level: nextLevelNotPause }
      : undefined,
    nextPause: nextPause
      ? { index: nextPauseIndex, level: nextPause }
      : undefined,
    secondsLeftInLevel,
    secondsLeftToNextPause,
  };
}

export function pauseOrResumeTournoi(
  tournoi: Pick<
    TournoiLive,
    | 'id'
    | 'status'
    | 'startDate'
    | 'lastRestartDate'
    | 'elapsedSecInTournament'
    | 'currentTimestamp'
  >,
) {
  switch (tournoi.status) {
    case 'running': {
      return upsertTournoiStatus({
        ...tournoi,
        status: 'paused',
        elapsedSecInTournament: getTournoiElapsedTimeInSeconds(
          tournoi,
          tournoi.currentTimestamp,
        ),
      });
    }
    case 'notStarted': {
      return upsertTournoiStatus({
        ...tournoi,
        status: 'running',
        startDate: new Date(),
        lastRestartDate: new Date(),
      });
    }
    case 'paused': {
      return upsertTournoiStatus({
        ...tournoi,
        status: 'running',
        lastRestartDate: new Date(),
        elapsedSecInTournament: tournoi.elapsedSecInTournament + 1, // le +1 est utile pour le cas des niveaux de pause sans durée sinon on reste bloqués
      });
    }
    case 'finished': {
      throw new Error('Impossible de redémarrer un tournoi fini');
    }
    default: {
      const switchCheck: never = tournoi.status;
      return switchCheck;
    }
  }
}

export async function ajouterKill(
  tournoi: Tournoi,
  values: AjouterKillValues,
  user?: Player,
) {
  const lastPosition = tournoi.players.reduce((acc, { position }) => {
    if (position && position < acc) {
      return position;
    }
    return acc;
  }, tournoi.players.length + 1);
  const position = lastPosition - 1;

  const isTournoiFinished = !values.isRecave && position === 2;

  const newPlayers: TournoiPlayer[] = [];
  for (const p of tournoi.players) {
    let updatedP = p;
    //Update du killer
    if (p.id === values.killerId) {
      updatedP = {
        ...p,
        position: isTournoiFinished ? 1 : undefined,
      };
    }
    //Update de la victime
    if (p.id === values.victimeId) {
      updatedP = {
        ...p,
        position: values.isRecave ? undefined : position,
      };
    }
    newPlayers.push(updatedP);
  }

  let updatedTournoi: Tournoi = {
    ...tournoi,
    players: newPlayers,
    killsHistory: [
      ...(tournoi.killsHistory ?? []),
      {
        heure: values.heure!,
        killerId: values.killerId!,
        victimeId: values.victimeId!,
        isRecave: !!values.isRecave,
        killEnregistrePar: user
          ? `${user.name} (email:${user.email} - id:${user.id})`
          : 'inconnu',
      },
    ],
  };

  if (isTournoiFinished) {
    updatedTournoi = {
      ...updatedTournoi,
      status: 'finished',
      killsHistory: [
        ...(updatedTournoi.killsHistory ?? []),
        {
          heure: values.heure!,
          killerId: values.killerId!,
          victimeId: null,
          isRecave: false,
          killEnregistrePar: user
            ? `${user.name} (email:${user.email} - id:${user.id})`
            : 'inconnu',
        },
      ],
    };
  }

  upsertTournoi(updatedTournoi);
}

export async function annulerDernierKill(tournoi: Tournoi) {
  const updatedTournoi = { ...tournoi };
  if (tournoi.status === 'finished') {
    updatedTournoi.status = 'paused';
    // La ligne du gagnant
    const gagnantToRemove = updatedTournoi.killsHistory?.pop();
    const gagnantIndex = tournoi.players.findIndex(
      ({ id }) => id === gagnantToRemove?.killerId,
    );
    if (gagnantIndex > -1) {
      const gagnant = tournoi.players[gagnantIndex];
      updatedTournoi.players[gagnantIndex] = {
        ...gagnant,

        position: undefined,
        prizeInPercent: undefined,
        prizeInEuro: undefined,
      };
    }
  }
  const killToRemove = updatedTournoi.killsHistory?.pop();
  if (killToRemove) {
    const victimeIndex = tournoi.players.findIndex(
      ({ id }) => id === killToRemove?.victimeId,
    );
    if (victimeIndex > -1) {
      const victime = tournoi.players[victimeIndex];
      updatedTournoi.players[victimeIndex] = {
        ...victime,
        killedBy: victime.killedBy.slice(0, -1), //suppression du dernier élément
        nbRecave: killToRemove.isRecave
          ? victime.nbRecave - 1
          : victime.nbRecave,
        position: undefined,
        prizeInPercent: undefined,
        prizeInEuro: undefined,
      };
    }
    const killerIndex = tournoi.players.findIndex(
      ({ id }) => id === killToRemove?.killerId,
    );
    if (killerIndex > -1) {
      const killer = tournoi.players[killerIndex];
      updatedTournoi.players[killerIndex] = {
        ...killer,
        nbKills: killer.nbKills - 1,
      };
    }
  }
  await upsertTournoi(updatedTournoi);
}

export function useTournoiSoundsAndToast(
  tournoi: TournoiLive,
  isSoundActive: boolean,
) {
  const toast = useToast({
    id: LIVE_TOAST_ID,
    position: 'top',
    duration: 10000,
  });
  const [previousTournoi, setPreviousTournoi] = useState(tournoi);
  const { parle, isParleLoading } = useParle();
  const { joueSon, isSonPlaying } = useSons();

  const {
    secondsLeftInLevel,
    currentLevel,
    nextLevel,
    status,
    killsHistory,
    players,
  } = tournoi;

  const onDemarrerTournoi = useCallback(async () => {
    await joueSon('paquebot', 4, false);
    parle('Cheuffeule up, and dil');
  }, [joueSon, parle]);

  const onFinishedTournoi = useCallback(async () => {
    //on ne prend pas le dernier sinon on a pas la victime
    const lastKill = killsHistory[killsHistory.length - 2];

    const killer = players.find(({ id }) => id === lastKill.killerId);
    const victime = players.find(({ id }) => id === lastKill.victimeId);

    await joueSon('joie', 3, false);

    if (killer) {
      const generatePhrase = (killerName: string, victimeName?: string) => {
        return `${killerName} a gagné le tournoi${
          killer?.prizeInEuro
            ? `, et repart avec ${formatMoney(killer.prizeInEuro)}`
            : ''
        }${
          victime && victimeName
            ? `, ${victimeName} termine en 2ème position${
                victime.prizeInEuro
                  ? `, et gagne ${formatMoney(victime.prizeInEuro)}`
                  : ''
              }`
            : ''
        }`;
      };

      parle(
        generatePhrase(
          getAudioPlayerName(killer),
          victime ? getAudioPlayerName(victime) : undefined,
        ),
      );
      if (!toast.isActive(LIVE_TOAST_ID)) {
        toast({
          duration: 20000,
          render: ({ onClose }) => (
            <TournoiLiveToast
              title="Fin du tournoi"
              description={generatePhrase(killer.name, victime?.name)}
              type="win"
              onClose={onClose}
            />
          ),
        });
      }
      setPreviousTournoi(tournoi);
    }
  }, [joueSon, killsHistory, parle, players, toast, tournoi]);

  const onMiseEnPause = useCallback(async () => {
    await joueSon('toast', 2, false);
    parle(getRandomSon('MISE_EN_PAUSE'));
  }, [joueSon, parle]);

  const onRedemarrage = useCallback(async () => {
    await joueSon('cassette_audio', 2, false);
    parle(getRandomSon('REPRISE'));
  }, [joueSon, parle]);

  const onNouveauNiveauDans1Minute = useCallback(async () => {
    await joueSon('tictac', 2, true);
    if (!currentLevel.level.isPause) {
      if (nextLevel?.level.isPause) {
        parle(getRandomSon('PAUSE_1_MIN'));
      } else if (nextLevel) {
        const { bigBlind, smallBlind } = nextLevel.level;
        parle(`Dans 1 minute, passage aux blindes ${smallBlind}, ${bigBlind}`);
      }
    } else {
      const { bigBlind, smallBlind } = nextLevel?.level || {};
      parle(
        `Reprise du tournoi dans 1 minute${
          bigBlind && smallBlind
            ? `, passage aux blindes ${smallBlind}, ${bigBlind}`
            : ''
        }`,
      );
    }
  }, [currentLevel.level.isPause, joueSon, nextLevel, parle]);

  const onPassageDeNiveaux = useCallback(async () => {
    await joueSon('gong', 2, false);
    if (currentLevel.level.isPause) {
      parle(getRandomSon('PAUSE'));
    } else {
      const { bigBlind, smallBlind } = currentLevel.level;
      parle(`Passage aux blindes ${smallBlind}, ${bigBlind}`);
    }
  }, [currentLevel.level, joueSon, parle]);

  const onKill = useCallback(async () => {
    const lastKill = killsHistory[killsHistory.length - 1];
    const killer = players.find(({ id }) => id === lastKill.killerId);
    const victime = players.find(({ id }) => id === lastKill.victimeId);

    if (lastKill.isRecave) {
      await joueSon('piece', 2, false);
      if (victime) {
        parle(getRandomSon('RECAVE', killer, victime));
        if (!toast.isActive(LIVE_TOAST_ID)) {
          toast({
            render: ({ onClose }) => (
              <TournoiLiveToast
                title="Recave"
                description={`${victime.name} a recavé${
                  killer?.name ? `, merci ${killer?.name}` : ''
                }`}
                type="recave"
                onClose={onClose}
              />
            ),
          });
        }
      }
    } else {
      await joueSon('pistolet', 2, false);
      if (victime) {
        parle(getRandomSon('ELIMINATION', killer, victime));
        if (!toast.isActive(LIVE_TOAST_ID)) {
          toast({
            render: ({ onClose }) => (
              <TournoiLiveToast
                title="Elimination"
                description={`${victime.name} a été éliminé en ${
                  victime.position
                }ème position${killer?.name ? `, par ${killer.name}` : ''}${
                  victime.prizeInEuro
                    ? `, et gagne ${formatMoney(victime.prizeInEuro)}`
                    : ''
                }`}
                type="kill"
                onClose={onClose}
              />
            ),
          });
        }
      }
    }
  }, [joueSon, killsHistory, parle, players, toast]);

  useEffect(
    () =>
      tournoi.currentTimestamp !== previousTournoi.currentTimestamp
        ? setPreviousTournoi(tournoi)
        : undefined,
    [previousTournoi.currentTimestamp, tournoi],
  );

  useEffect(() => {
    if (!isSoundActive || isParleLoading || isSonPlaying) {
      return;
    }

    if (status === 'finished' && previousTournoi.status !== 'finished') {
      onFinishedTournoi();
    }

    if (previousTournoi.currentTimestamp === tournoi.currentTimestamp) {
      return;
    }

    if (status === 'running' && previousTournoi.status === 'notStarted') {
      onDemarrerTournoi();
    } else if (
      status === 'paused' &&
      previousTournoi.status === 'running' &&
      currentLevel.level.duree > 0 //Dans le cas de la mise en pause auto je ne veux pas entendre ça
    ) {
      onMiseEnPause();
    } else if (status === 'running' && previousTournoi.status === 'paused') {
      onRedemarrage();
    } else if (
      secondsLeftInLevel === 60 &&
      previousTournoi.secondsLeftInLevel === 61
    ) {
      onNouveauNiveauDans1Minute();
    } else if (
      secondsLeftInLevel === currentLevel.level.duree * 60 &&
      previousTournoi.secondsLeftInLevel === 1
    ) {
      onPassageDeNiveaux();
    } else if (killsHistory.length > previousTournoi.killsHistory.length) {
      onKill();
    }
  }, [
    isParleLoading,
    isSonPlaying,
    isSoundActive,
    parle,
    currentLevel.level.duree,
    secondsLeftInLevel,
    status,
    killsHistory,
    previousTournoi.secondsLeftInLevel,
    previousTournoi.status,
    previousTournoi.killsHistory,
    onDemarrerTournoi,
    onMiseEnPause,
    onRedemarrage,
    onNouveauNiveauDans1Minute,
    onPassageDeNiveaux,
    onKill,
    onFinishedTournoi,
    previousTournoi.currentTimestamp,
    tournoi.currentTimestamp,
  ]);
}

export function appendVh(heightVar: number) {
  return `${heightVar}vh`;
}

export function mapIpadRemToVh(ipadRem: number) {
  const IPAD_HEIGHT = 700;
  const REM = 16;
  return appendVh(ipadRem * (REM / IPAD_HEIGHT) * 100);
}
