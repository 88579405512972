import {
  ALL_SEATS,
  PokerRange,
  PokerRangePosition,
  Seat,
} from '../ranges.type';
import RangeCard from './RangeCard';
import { useMemo, useState } from 'react';
import { Button, ButtonGroup, VStack } from '@chakra-ui/react';
import {
  formatRangeSeatsLabel,
  formatRangeStackLabel,
  formatRangeTableLabel,
} from '../ranges.helper';
import { RoutePath } from 'router/routes.paths';

type CompareFilters = {
  categories?: PokerRange['category'][];
  stacks?: NonNullable<PokerRange['stack']>[];
  positions?: PokerRange['positions'];
  tables?: number[];
};

type SelectedFilters = {
  category?: PokerRange['category'];
  stack?: NonNullable<PokerRange['stack']>;
  position?: PokerRangePosition;
  table?: number[];
};

function computeCompareFilters(rangesFiltered: PokerRange[]): CompareFilters {
  const categories = rangesFiltered.reduce<PokerRange['category'][]>(
    (acc, range) =>
      acc.includes(range.category) ? acc : [...acc, range.category],
    [],
  );
  const stacks = rangesFiltered.reduce<NonNullable<PokerRange['stack']>[]>(
    (acc, range) =>
      acc.findIndex(
        ({ min, max }) => min === range.stack?.min && max === range.stack?.max,
      ) === -1
        ? [...acc, range.stack!]
        : acc,
    [],
  );
  const positions = rangesFiltered.reduce<PokerRange['positions']>(
    (acc, range) => {
      range.positions.forEach((position) => {
        const positionIndexInAcc = acc.findIndex(({ table, seats }) => {
          if (table === position.table) {
            return seats.every((seat) => position.seats.includes(seat));
          }
          return false;
        });
        if (positionIndexInAcc === -1) {
          acc.push(position);
        }
      });
      return acc;
    },
    [],
  );
  const tables = positions.reduce<number[]>(
    (acc, { table }) =>
      table === undefined || acc.includes(table) ? acc : [...acc, table],
    [],
  );

  function findSmallestSeatIndex(seats: Seat[]) {
    return seats.reduce((acc, seat) => {
      const seatIndex = ALL_SEATS.findIndex((s) => s === seat);
      return Math.min(acc, seatIndex);
    }, 99);
  }
  return {
    categories: categories.length > 1 ? categories : undefined,
    stacks: stacks.length > 1 ? stacks : undefined,
    positions:
      positions.length > 1
        ? positions.sort(
            (p1, p2) =>
              findSmallestSeatIndex(p1.seats) - findSmallestSeatIndex(p2.seats),
          )
        : undefined,
    tables: tables.length > 1 ? tables : undefined,
  };
}

// TODO VERSUS: ajouter le versus ici
export default function RangeCompare({
  ranges,
}: Readonly<{ ranges: PokerRange[] }>) {
  const compareFilters = useMemo(() => computeCompareFilters(ranges), [ranges]);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    category:
      compareFilters.categories?.find((v) => v === 'Open') ??
      compareFilters.categories?.[0],
    stack: compareFilters.stacks?.length
      ? compareFilters.stacks.reduce(
          (acc, stack) => (stack.min > acc.min ? stack : acc),
          compareFilters.stacks[0],
        )
      : undefined,
    position:
      compareFilters.positions?.find((v) => v.table === 6) ??
      compareFilters.positions?.[0],
  });

  console.log('compareFilters', compareFilters);

  const selectedRanges = useMemo(
    () =>
      ranges.filter((range) => {
        if (
          selectedFilters.category &&
          range.category !== selectedFilters.category
        ) {
          return false;
        }
        if (
          selectedFilters.stack &&
          range.stack &&
          (range.stack.min !== selectedFilters.stack.min ||
            range.stack.max !== selectedFilters.stack.max)
        ) {
          return false;
        }
        if (
          selectedFilters.position &&
          !range.positions.some(
            (position) =>
              position.table === selectedFilters.position!.table &&
              position.seats.every((seat) =>
                selectedFilters.position!.seats.includes(seat),
              ),
          )
        ) {
          return false;
        }
        return true;
      }),
    [ranges, selectedFilters],
  );

  return (
    <>
      <VStack mb={3}>
        {compareFilters.categories ? (
          <ButtonGroup isAttached>
            {compareFilters.categories.map((category) => (
              <Button
                key={category}
                size={'xs'}
                variant={'outline'}
                onClick={() =>
                  setSelectedFilters((prev) => ({ ...prev, category }))
                }
                isActive={selectedFilters.category === category}
              >
                {category}
              </Button>
            ))}
          </ButtonGroup>
        ) : null}

        {compareFilters.tables ? (
          <>
            <ButtonGroup isAttached>
              {compareFilters.tables.map((table) => {
                const tableName = formatRangeTableLabel(table);
                return (
                  <Button
                    key={tableName}
                    size={'xs'}
                    variant={'outline'}
                    onClick={() =>
                      setSelectedFilters((prev) => ({
                        ...prev,
                        position: compareFilters.positions?.find(
                          (position) => position.table === table,
                        ),
                      }))
                    }
                    isActive={
                      selectedFilters.position &&
                      selectedFilters.position.table === table
                    }
                  >
                    {tableName}
                  </Button>
                );
              })}
            </ButtonGroup>
          </>
        ) : null}
        {compareFilters.positions ? (
          <>
            <ButtonGroup isAttached>
              {compareFilters.positions
                .filter(
                  (position) =>
                    position.table === selectedFilters.position?.table,
                )
                .map((position) => {
                  const seatName = formatRangeSeatsLabel(position.seats);
                  return (
                    <Button
                      key={seatName}
                      size={'xs'}
                      variant={'outline'}
                      onClick={() =>
                        setSelectedFilters((prev) => ({ ...prev, position }))
                      }
                      isActive={selectedFilters.position === position}
                    >
                      {seatName}
                    </Button>
                  );
                })}
            </ButtonGroup>
          </>
        ) : null}

        {compareFilters.stacks ? (
          <ButtonGroup isAttached>
            {compareFilters.stacks.map((stack) => {
              const stackLabel = formatRangeStackLabel(stack);
              return (
                <Button
                  key={stackLabel}
                  size={'xs'}
                  variant={'outline'}
                  onClick={() =>
                    setSelectedFilters((prev) => ({ ...prev, stack }))
                  }
                  isActive={selectedFilters.stack === stack}
                >
                  {stackLabel}
                </Button>
              );
            })}
          </ButtonGroup>
        ) : null}
      </VStack>
      {selectedRanges.length > 0 && (
        <VStack mb={3}>
          {selectedRanges.map((selectedRange) => (
            <RangeCard
              range={selectedRange}
              w="100%"
              linkTo={`${RoutePath.ranges}/${selectedRange.id}`}
              withPercentAndNote
            />
          ))}
        </VStack>
      )}
    </>
  );
}
