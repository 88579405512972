import { PokerRange, RangeType } from 'features/ranges/ranges.type';
import BPCHandMatrix from './BPCHandMatrix';
import { findRangeTypeForCombo } from 'features/ranges/ranges.helper';
import { useState } from 'react';
import { StyleProps } from '@chakra-ui/react';
import { Combo } from './HandMatrix';

export default function BPCHandMatrixEditable({
  rangeDetails,
  selectedRangeType,
  onChange,
  style,
}: Readonly<{
  rangeDetails: PokerRange['details'];
  selectedRangeType?: RangeType;
  onChange: (newDetails: PokerRange['details']) => void;
  style?: StyleProps;
}>) {
  const [isPointerDown, setIsPointerDown] = useState(false);

  function onComboSelect(combo: Combo, allowUnselection = true) {
    const currentRangeTypeForCombo = findRangeTypeForCombo(combo, rangeDetails);

    const newDetails: PokerRange['details'] = {
      ...rangeDetails,
      [combo]:
        (allowUnselection && currentRangeTypeForCombo === selectedRangeType) ||
        !selectedRangeType
          ? undefined
          : { [selectedRangeType]: 1 },
    };
    onChange(newDetails);
  }

  return (
    <BPCHandMatrix
      style={style}
      rangeDetails={rangeDetails}
      onPointerDown={(combo) => {
        if (!combo) {
          return;
        }
        setIsPointerDown(true);
        onComboSelect(combo, true);
      }}
      onPointerUp={() => {
        setIsPointerDown(false);
      }}
      onPointerEnter={(combo) =>
        isPointerDown && combo ? onComboSelect(combo, false) : undefined
      }
    />
  );
}
