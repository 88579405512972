import { PokerRange } from '../../ranges.type';
import { findRangeTypeColorForCombo } from '../../ranges.helper';
import { Box, StyleProps, useBreakpointValue } from '@chakra-ui/react';

import './BPCHandMatrix.css';
import { HandMatrix, HandMatrixProps } from './HandMatrix';

//TODO: ajouter des sélections rapides: tous les Axs, Axo, tous les broadways, toutes les pockets, suited connectors

export default function BPCHandMatrix({
  rangeDetails,
  style,
  colorize = true,
  smallFontSize: smallFontSizeOverride = true,
  ...restHandMatrixProps
}: {
  rangeDetails: PokerRange['details'];
  smallFontSize?: boolean;
  style?: StyleProps;
} & Omit<HandMatrixProps, 'comboStyle'>) {
  const smallFontSizeDefault = useBreakpointValue({ base: true, md: false });
  const smallFontSize =
    smallFontSizeOverride === undefined
      ? smallFontSizeDefault
      : smallFontSizeOverride;
  return (
    <Box
      className={smallFontSize ? 'small-font-size' : ''}
      maxW="500px"
      {...style}
    >
      <HandMatrix
        colorize={colorize}
        comboStyle={(combo) => ({
          background: findRangeTypeColorForCombo(combo, rangeDetails),
        })}
        {...restHandMatrixProps}
      />
    </Box>
  );
}
