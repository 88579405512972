import { useMemo } from 'react';
import { PokerRange, RangeType } from '../ranges.type';
import { Box, Flex, Text, VStack, Heading, Stack } from '@chakra-ui/react';
import { rangeTypeColors, rangeTypeNames } from '../ranges.constants';
import {
  computeRangePercentageForNbCombos,
  computeTotalCombosForRange,
  displayTotalForRange,
} from '../ranges.helper';
import BPCHandMatrix from './BPCHandMatrix/BPCHandMatrix';

export default function RangeDetail({
  range,
}: Readonly<{
  range: PokerRange;
}>) {
  const nbCombosPerType = useMemo(
    () => computeTotalCombosForRange(range.details),
    [range.details],
  );

  const rangeTypesToDisplay = useMemo(
    () =>
      Object.keys(nbCombosPerType).filter(
        (type) => type !== 'total',
      ) as RangeType[],
    [nbCombosPerType],
  );

  const total = useMemo(
    () => displayTotalForRange(nbCombosPerType),
    [nbCombosPerType],
  );
  return (
    <VStack spacing={3} alignItems={'flex-start'} width={'100%'}>
      {range.name && <Heading size={'md'}>{range.name}</Heading>}
      <Text>Total : {total}</Text>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        alignItems={'flex-start'}
        spacing={2}
      >
        {rangeTypesToDisplay.map((type) => {
          const rangePercentage = computeRangePercentageForNbCombos(
            nbCombosPerType[type],
          );
          return (
            <Box key={type}>
              <Flex alignItems={'center'}>
                <Box bg={rangeTypeColors[type]} h="1rem" w="1rem" mr={2} />
                <Text fontSize={'sm'}>{`${rangeTypeNames[type]}${
                  rangePercentage ? ` (${rangePercentage})` : ''
                }`}</Text>
              </Flex>
            </Box>
          );
        })}
      </Stack>
      {range.note && <Text>{range.note}</Text>}
      <BPCHandMatrix rangeDetails={range.details} style={{ width: '100%' }} />
    </VStack>
  );
}
